
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import { defineAsyncComponent } from '@vue/composition-api'

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import TextareaAutosize from 'vue-textarea-autosize'
import location from '@/mixins/location.js'
import referrer from '@/mixins/referrer.js'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

import Checkoutable from '@/components/Checkoutable'
import FormsPaymentForm from '@/components/forms/PaymentForm'

Vue.use(VueTelInput)
Vue.use(TextareaAutosize)

export default {
  layout: 'checkout',

  components: {
    Checkoutable,
    FormsPaymentForm,
    LazyGoogleMap: defineAsyncComponent(() => import('@/components/GoogleMap'))
  },

  mixins: [
    location,
    referrer,
    clickaway,
    vueTelInputAllCountries
  ],

  data () {
    return {
      tabs: [
        'user',
        'delivery',
        'payment'
      ],
      user: {
        phone: {
          focus: false,
          country: undefined
        }
      },
      delivery: {
        city: {
          active: false,
          loading: false,
          timeout: null,
          timer: 200
        },
        filial: {
          active: false,
          loading: false
        },
        methods: {
          loading: false
        },
        recipient: {
          checkbox: true,
          phone: {
            focus: false,
            country: undefined
          }
        }
      },
      payment: {
        company: {
          active: false,
          loading: false
        },
        term: {
          active: false
        },
        installment: {
          phone: {
            focus: false,
            country: undefined
          },
          employer: {
            phone: {
              focus: false,
              country: undefined
            }
          },
          requireNameFields: [
            'novaposhta.installment',
            'paylater.installment',
            'ideabank.installment'
          ],
          requirePhone: [
            'novaposhta.installment',
            'paylater.installment',
            'ideabank.installment',
            'platon.monobank.installment'
          ],
          requireEmail: [
            'paylater.installment',
            'ideabank.installment',
            'platon.monobank.installment'
          ],
          requireBirthdayField: [
            // 'paylater.installment'
          ],
          requireTinField: [
            // 'novaposhta.installment',
            // 'paylater.installment',
            // 'ideabank.installment'
          ],
          requirePassportFields: [
            // 'paylater.installment'
          ],
          requireAddressFields: [
            // 'paylater.installment'
          ],
          requireEducationFields: [
            // 'paylater.installment'
          ],
          requireEmployerFields: [
            // 'paylater.installment'
          ],
          requireEmployerOccupationField: [
            // 'ideabank.installment'
          ],
          requireFamilyFields: [
            // 'paylater.installment'
          ],
          // requireThirdPersonFields: [
          //   'paylater.installment'
          // ],
          requireNoteFields: [
            'paylater.installment',
            'ideabank.installment'
            // 'platon.monobank.installment'
          ]
        },
        tabs: [
          {
            hash: 'cart',
            name: 'cart.title'
          },
          {
            hash: 'installment',
            name: 'installment.title'
          }
        ]
      },
      notification: {
        active: false
      },
      buttons: {
        checkout: {
          loading: false
        }
      },
      note: {
        show: false
      },
      contents: {
        show: true
      }
    }
  },

  computed: {
    ...mapGetters({
      authModal: 'modal/auth',
      cart: 'cart/cart',
      checkout: 'checkout/checkout',
      order: 'order/order',
      currency: 'currency',
      localDeliveryOptions: 'localDeliveryOptions',
      location: 'location',
      country: 'country',
      countries: 'countries',
      settings: 'settings',
      logo: 'logo'
    }),

    userNameValue: {
      get () {
        return this.checkout.form.user.name.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserNameValue', value)
      }
    },

    userLastnameValue: {
      get () {
        return this.checkout.form.user.lastname.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserLastnameValue', value)
      }
    },

    userPatronymicValue: {
      get () {
        return this.checkout.form.user.patronymic.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserPatronymicValue', value)
      }
    },

    userPhoneModel: {
      get () {
        return this.checkout.form.user.phone.model
      },
      set (value) {
        this.$store.dispatch('checkout/setUserPhoneModel', value)
      }
    },

    userEmailValue: {
      get () {
        return this.checkout.form.user.email.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserEmailValue', value)
      }
    },

    deliveryCityQuery: {
      get () {
        return this.checkout.form.delivery.city.query
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryCityQuery', value || '')
      }
    },

    deliveryMethodModel: {
      get () {
        return this.checkout.form.delivery.method
      },
      set (value) {
        this.changeDeliveryMethod(this.checkout.form.delivery.methods.items.find(item => item.id === value))
      }
    },

    deliveryFilialQuery: {
      get () {
        return this.checkout.form.delivery.filial.query
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryFilialQuery', value || '')
      }
    },

    deliveryAddressStreetValue: {
      get () {
        return this.checkout.form.delivery.address.street.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryAddressStreetValue', value)
      }
    },

    deliveryAddressZipValue: {
      get () {
        return this.checkout.form.delivery.address.zip.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryAddressZipValue', value)
      }
    },

    deliveryAddressHouseValue: {
      get () {
        return this.checkout.form.delivery.address.house.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryAddressHouseValue', value)
      }
    },

    deliveryAddressApartmentValue: {
      get () {
        return this.checkout.form.delivery.address.apartment.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryAddressApartmentValue', value)
      }
    },

    deliveryAddressFloorValue: {
      get () {
        return this.checkout.form.delivery.address.floor.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryAddressFloorValue', value)
      }
    },

    deliveryAddressIntercomValue: {
      get () {
        return this.checkout.form.delivery.address.intercom.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryAddressIntercomValue', value)
      }
    },

    deliveryRecipientNameValue: {
      get () {
        return this.checkout.form.delivery.recipient.name.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryRecipientNameValue', value)
      }
    },

    deliveryRecipientLastnameValue: {
      get () {
        return this.checkout.form.delivery.recipient.lastname.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryRecipientLastnameValue', value)
      }
    },

    deliveryRecipientPatronymicValue: {
      get () {
        return this.checkout.form.delivery.recipient.patronymic.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryRecipientPatronymicValue', value)
      }
    },

    deliveryRecipientPhoneModel: {
      get () {
        return this.checkout.form.delivery.recipient.phone.model
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryRecipientPhoneModel', value)
      }
    },

    deliveryRecipientEmailValue: {
      get () {
        return this.checkout.form.delivery.recipient.email.value
      },
      set (value) {
        this.$store.dispatch('checkout/setDeliveryRecipientEmailValue', value)
      }
    },

    paymentMethodModel: {
      get () {
        return this.checkout.form.payment.method
      },
      set (value) {
        this.changePaymentMethod(value)
      }
    },

    installmentInitial: {
      get () {
        return this.checkout.form.payment.installment.initial
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentInitial', value)
      }
    },

    installmentName: {
      get () {
        return this.checkout.form.payment.installment.name.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentName', value)
      }
    },

    installmentLastname: {
      get () {
        return this.checkout.form.payment.installment.lastname.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentLastname', value)
      }
    },

    installmentPatronymic: {
      get () {
        return this.checkout.form.payment.installment.patronymic.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPatronymic', value)
      }
    },

    installmentPhoneModel: {
      get () {
        return this.checkout.form.payment.installment.phone.model
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPhoneModel', value)
      }
    },

    installmentEmailValue: {
      get () {
        return this.checkout.form.payment.installment.email.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEmail', value)
      }
    },

    installmentBirthday: {
      get () {
        return this.checkout.form.payment.installment.birthday.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentBirthday', value)
      }
    },

    installmentTinValue: {
      get () {
        return this.checkout.form.payment.installment.tin.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentTin', value)
      }
    },

    installmentPassportSeriesValue: {
      get () {
        return this.checkout.form.payment.installment.passport.series.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPassportSeries', value)
      }
    },

    installmentPassportNumberValue: {
      get () {
        return this.checkout.form.payment.installment.passport.number.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPassportNumber', value)
      }
    },

    installmentPassportIssuerValue: {
      get () {
        return this.checkout.form.payment.installment.passport.issuer.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPassportIssuer', value)
      }
    },

    installmentPassportIssueDateValue: {
      get () {
        return this.checkout.form.payment.installment.passport.issue_date.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPassportIssueDate', value)
      }
    },

    installmentPassportExpirationDateValue: {
      get () {
        return this.checkout.form.payment.installment.passport.expiration_date.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentPassportExpirationDate', value)
      }
    },

    installmentAddressRegistrationValue: {
      get () {
        return this.checkout.form.payment.installment.address.registration.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentAddressRegistration', value)
      }
    },

    installmentAddressResidenceValue: {
      get () {
        return this.checkout.form.payment.installment.address.residence.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentAddressResidence', value)
      }
    },

    installmentAddressZipValue: {
      get () {
        return this.checkout.form.payment.installment.address.zip.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentAddressZip', value)
      }
    },

    installmentEducationDegreeValue: {
      get () {
        return this.checkout.form.payment.installment.education.degree.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEducationDegree', value)
      }
    },

    installmentEducationInstitutionValue: {
      get () {
        return this.checkout.form.payment.installment.education.institution.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEducationInstitution', value)
      }
    },

    installmentEmployerTitleValue: {
      get () {
        return this.checkout.form.payment.installment.employer.title.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEmployerTitle', value)
      }
    },

    installmentEmployerPhoneModel: {
      get () {
        return this.checkout.form.payment.installment.employer.phone.model
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEmployerPhoneModel', value)
      }
    },

    installmentEmployerAddressValue: {
      get () {
        return this.checkout.form.payment.installment.employer.address.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEmployerAddress', value)
      }
    },

    installmentEmployerJobTitleValue: {
      get () {
        return this.checkout.form.payment.installment.employer.job_title.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEmployerJobTitle', value)
      }
    },

    installmentEmployerOccupationValue: {
      get () {
        return this.checkout.form.payment.installment.employer.occupation.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentEmployerOccupation', value)
      }
    },

    installmentFamilyStatusValue: {
      get () {
        return this.checkout.form.payment.installment.family.status.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentFamilyStatus', value)
      }
    },

    installmentFamilyNumberOfChildrenValue: {
      get () {
        return this.checkout.form.payment.installment.family.number_of_children.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentFamilyNumberOfChildren', value)
      }
    },

    installmentFamilyAgeOfChildrenValue: {
      get () {
        return this.checkout.form.payment.installment.family.age_of_children.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentFamilyAgeOfChildren', value)
      }
    },

    installmentFamilyNumberOfInhabitantsValue: {
      get () {
        return this.checkout.form.payment.installment.family.number_of_inhabitants.value
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentFamilyNumberOfInhabitants', value)
      }
    },

    installmentNoteBody: {
      get () {
        return this.checkout.form.payment.installment.note.body
      },
      set (value) {
        this.$store.dispatch('checkout/setInstallmentNoteBody', value)
      }
    },

    noteBody: {
      get () {
        return this.checkout.form.note.body
      },
      set (value) {
        this.$store.dispatch('checkout/setNoteBody', value)
      }
    },

    callback: {
      get () {
        return this.checkout.form.callback
      },
      set (value) {
        this.$store.dispatch('checkout/setCallback', value)
      }
    },

    cartItemsQuantity () {
      return this.cart.items.reduce((total, item) => total + item.quantity, 0)
    },

    minStoreShipmentTime () {
      if (this.cart.items.length) {
        return this.cart.items.reduce((prev, curr) => {
          return prev.store.shipment_min < curr.store.shipment_min ? prev : curr
        }).store
      } else {
        return 0
      }
    },

    maxStoreShipmentTime () {
      if (this.cart.items.length) {
        return this.cart.items.reduce((prev, curr) => {
          return prev.store.shipment_max > curr.store.shipment_max ? prev : curr
        }).store
      } else {
        return 0
      }
    },

    storeShipmentTimeOfSelectedDeliveryMethod () {
      // Add 1 day if the current time is more than the shipment_before time
      const shipTodayAddDays = this.$moment().locale(this.$i18n.locale).format('HH:mm:ss') < this.selectedDeliveryMethod.shipment_before ? 0 : 1

      const from = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.minStoreShipmentTime.shipment_min, `${this.minStoreShipmentTime.shipment_min_unit.name}s`)
        .add(this.minStoreShipmentTime.stockStatus.shipment_time_min, `${this.minStoreShipmentTime.stockStatus.shipment_time_min_unit.name}s`)
        .add(this.selectedDeliveryMethod.delivery_time_min, `${this.selectedDeliveryMethod.delivery_time_min_unit.name}s`)

      const to = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.maxStoreShipmentTime.shipment_max, `${this.maxStoreShipmentTime.shipment_max_unit.name}s`)
        .add(this.maxStoreShipmentTime.stockStatus.shipment_time_max, `${this.maxStoreShipmentTime.stockStatus.shipment_time_max_unit.name}s`)
        .add(this.selectedDeliveryMethod.delivery_time_max, `${this.selectedDeliveryMethod.delivery_time_max_unit.name}s`)

      if (to.year() === from.year() && to.month() === from.month() && to.date() === from.date()) {
        return to.format('DD MMMM')
      } else if (to.year() === from.year() && to.month() === from.month() && to.date() !== from.date()) {
        return `${from.format('DD')} — ${to.format('DD MMMM')}`
      } else if (to.year() === from.year() && to.month() !== from.month() && to.date() !== from.date()) {
        return `${from.format('DD MMM')} — ${to.format('DD MMM')}`
      } else {
        return `${from.format('ll')} — ${to.format('ll')}`
      }
    },

    cartItemsByStore () {
      return this.cart.items.reduce((result, item) => {
        result[item.store.id] = result[item.store.id] || []
        result[item.store.id].push(item)
        return result
      }, Object.create(null))
    },

    selectedDeliveryMethodFilteredFilials () {
      const query = this.checkout.form.delivery.filial.query.toLowerCase()

      return this.selectedDeliveryMethod.filials.filter(filial => filial.name.toLowerCase().includes(query) || filial.address.toLowerCase().includes(query))
    },

    userIsValid () {
      return !this.checkout.form.user.name.error &&
        this.checkout.form.user.name.value &&
        !this.checkout.form.user.phone.error &&
        this.checkout.form.user.phone.number &&
        !this.checkout.form.user.email.error // &&
        // this.checkout.form.user.email.value
    },

    deliveryIsValid () {
      let isValid = !!this.checkout.form.delivery.method

      if (isValid && this.selectedDeliveryMethod && this.selectedDeliveryMethod.type === 'filial') {
        isValid = isValid && !!this.checkout.form.delivery.filial.value.id
      } else if (isValid && this.selectedDeliveryMethod && this.selectedDeliveryMethod.type === 'address') {
        isValid = isValid &&
          !!this.checkout.form.delivery.address.street.value &&
          !!this.checkout.form.delivery.address.zip.value &&
          !!this.checkout.form.delivery.address.house.value
      }

      if (!this.delivery.recipient.checkbox) {
        isValid = isValid &&
          !this.checkout.form.delivery.recipient.name.error &&
          !!this.checkout.form.delivery.recipient.name.value &&
          !this.checkout.form.delivery.recipient.phone.error &&
          !!this.checkout.form.delivery.recipient.phone.number &&
          !this.checkout.form.delivery.recipient.email.error // &&
          // !!this.checkout.form.delivery.recipient.email.value
      }

      return isValid
    },

    paymentIsValid () {
      let isValid = !!this.checkout.form.payment.method

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireNameFields.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.name.error &&
          !!this.checkout.form.payment.installment.name.value &&
          !this.checkout.form.payment.installment.lastname.error &&
          !!this.checkout.form.payment.installment.lastname.value &&
          !this.checkout.form.payment.installment.patronymic.error &&
          !!this.checkout.form.payment.installment.patronymic.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requirePhone.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.phone.error &&
          !!this.checkout.form.payment.installment.phone.number
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireEmail.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.email.error &&
          !!this.checkout.form.payment.installment.email.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireBirthdayField.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.birthday.error &&
          !!this.checkout.form.payment.installment.birthday.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireTinField.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.tin.error &&
          !!this.checkout.form.payment.installment.tin.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requirePassportFields.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.passport.series.error &&
          !!this.checkout.form.payment.installment.passport.series.value &&
          !this.checkout.form.payment.installment.passport.number.error &&
          !!this.checkout.form.payment.installment.passport.number.value &&
          !this.checkout.form.payment.installment.passport.issuer.error &&
          !!this.checkout.form.payment.installment.passport.issuer.value &&
          !this.checkout.form.payment.installment.passport.issue_date.error &&
          !!this.checkout.form.payment.installment.passport.issue_date.value &&
          !this.checkout.form.payment.installment.passport.expiration_date.error &&
          !!this.checkout.form.payment.installment.passport.expiration_date.value &&
          !this.checkout.form.payment.installment.address.registration.error
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireAddressFields.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !!this.checkout.form.payment.installment.address.registration.value &&
          !this.checkout.form.payment.installment.address.residence.error &&
          !!this.checkout.form.payment.installment.address.residence.value &&
          !this.checkout.form.payment.installment.address.zip.error &&
          !!this.checkout.form.payment.installment.address.zip.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireEducationFields.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.education.degree.error &&
          !!this.checkout.form.payment.installment.education.degree.value &&
          !this.checkout.form.payment.installment.education.institution.error &&
          !!this.checkout.form.payment.installment.education.institution.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireEmployerFields.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.employer.title.error &&
          !!this.checkout.form.payment.installment.employer.title.value &&
          !this.checkout.form.payment.installment.employer.phone.error &&
          !!this.checkout.form.payment.installment.employer.phone.number &&
          !this.checkout.form.payment.installment.employer.address.error &&
          !!this.checkout.form.payment.installment.employer.address.value &&
          !this.checkout.form.payment.installment.employer.job_title.error &&
          !!this.checkout.form.payment.installment.employer.job_title.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireEmployerOccupationField.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.employer.occupation.error &&
          !!this.checkout.form.payment.installment.employer.occupation.value
      }

      if (this.checkout.form.payment.tab === 'installment' &&
        this.payment.installment.requireFamilyFields.includes(this.cart.creditProduct.service)
      ) {
        isValid = isValid &&
          !this.checkout.form.payment.installment.family.status.error &&
          !!this.checkout.form.payment.installment.family.status.value &&
          !this.checkout.form.payment.installment.family.number_of_children.error &&
          !!this.checkout.form.payment.installment.family.number_of_children.value &&
          !this.checkout.form.payment.installment.family.age_of_children.error &&
          !!this.checkout.form.payment.installment.family.age_of_children.value &&
          !this.checkout.form.payment.installment.family.number_of_inhabitants.error &&
          !!this.checkout.form.payment.installment.family.number_of_inhabitants.value
      }

      // if (this.checkout.form.payment.tab === 'installment' &&
      //   this.payment.installment.requireThirdPersonFields.includes(this.cart.creditProduct.service)
      // ) {
      //   isValid = isValid &&
      //     !this.checkout.form.payment.installment.third_person.full_name.error &&
      //     !!this.checkout.form.payment.installment.third_person.full_name.value &&
      //     !this.checkout.form.payment.installment.third_person.phone.error &&
      //     !!this.checkout.form.payment.installment.third_person.phone.value &&
      //     !this.checkout.form.payment.installment.third_person.relations.error &&
      //     !!this.checkout.form.payment.installment.third_person.relations.value
      // }

      return isValid
    },

    closestFilial () {
      if (this.selectedDeliveryMethod.filials.length && this.location.lat && this.location.lng) {
        return this.selectedDeliveryMethod.filials.reduce((a, b) => this.distanceToClosestFilial(a) < this.distanceToClosestFilial(b) ? a : b)
      } else {
        return false
      }
    },

    total () {
      return this.cart.items.reduce((total, item) => {
        return total + item.quantity * (item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0))
      }, 0)
    },

    totalDelivery () {
      if (this.checkout.form.delivery.methods.items.length > 0 && this.checkout.form.delivery.method &&
        ['delivery', 'payment'].includes(this.checkout.tab)
      ) {
        return this.selectedDeliveryMethod.price.current
      } else {
        return 0
      }
    },

    totalDiscount () {
      return this.cart.items.reduce((total, item) => {
        const price = item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0)

        let discount = 0

        if (item.coupon && item.coupon.discount.type.type === 'percent') {
          discount = Math.round(price * item.coupon.discount.percent / 100)
        } else if (item.coupon && item.coupon.discount.type.type === 'amount') {
          discount = item.coupon.discount.amount.amount
        }

        return total + item.quantity * discount
      }, 0)
    },

    totalCommission () {
      if (this.checkout.form.payment.method === null) {
        return 0
      }

      if (this.selectedPaymentMethod == null) {
        return 0
      } else if (this.selectedPaymentMethod.commission.type === 'percent') {
        return Math.ceil((this.total + (this.settings.delivery.include ? this.totalDelivery : 0) - this.totalDiscount) * this.selectedPaymentMethod.commission.value / 100)
      } else if (this.selectedPaymentMethod.commission.type === 'amount') {
        return this.selectedPaymentMethod.commission.value
      } else {
        return 0
      }
    },

    installmentCommission () {
      if (this.cart.type === 'cart') {
        return 0
      }

      const total = this.total + (this.settings.delivery.include ? this.totalDelivery : 0) - this.cart.installment.initial - this.totalDiscount

      if ('percent' in this.cart.term && !this.cart.creditProduct.included) {
        if (this.cart.creditProduct.calculation === 'simple') {
          return Math.ceil(total / 100 * this.cart.term.percent)
        } else if (this.cart.creditProduct.calculation === 'complex') {
          return Math.ceil((total / (1 - this.cart.term.percent / 100)) - total)
        } else if (this.cart.creditProduct.calculation === 'monthly') {
          return Math.ceil(total / 100 * this.cart.term.percent * this.cart.term.payments)
        }
      }

      return 0
    },

    grandTotal () {
      return this.total + (this.settings.delivery.include ? this.totalDelivery : 0) + this.totalCommission + this.installmentCommission - this.totalDiscount
    },

    installmentPerMonthPrice () {
      return Math.ceil((this.grandTotal - this.cart.installment.initial) / this.cart.term.payments)
    },

    checkoutSubmitTooltip () {
      let about
      const invalid = []

      if (!this.userIsValid) {
        invalid.push(this.$t('pages.checkout.button.confirm.tooltip.invalid.user'))
      }

      if (!this.deliveryIsValid || !['delivery', 'payment'].includes(this.checkout.tab)) {
        invalid.push(this.$t('pages.checkout.button.confirm.tooltip.invalid.delivery'))
      }

      if (!this.paymentIsValid || !['payment'].includes(this.checkout.tab)) {
        invalid.push(this.$t('pages.checkout.button.confirm.tooltip.invalid.payment'))
      }

      if (invalid.length > 0) {
        if (invalid.length === 1) {
          about = invalid[0]
        } else {
          about = invalid.slice(0, -1).join(', ') + ' ' + this.$t('and') + ' ' + invalid.slice(-1)
        }

        return this.$t('pages.checkout.button.confirm.tooltip.title', { about })
      } else {
        return null
      }
    },

    formDeliveryMethodPaymentMethods () {
      if (this.selectedDeliveryMethod) {
        return this.selectedDeliveryMethod.paymentMethods.filter(paymentMethod => (paymentMethod.tab === 'installment' && this.cart.creditProducts.filter(creditProduct => creditProduct.type === paymentMethod.type).length > 0) || paymentMethod.tab === 'cart')
      } else {
        return []
      }
    },

    selectedDeliveryMethod () {
      return this.checkout.form.delivery.methods.items.find(item => item.id === this.checkout.form.delivery.method)
    },

    selectedPaymentMethod () {
      return this.selectedDeliveryMethod
        ? this.selectedDeliveryMethod.paymentMethods.find(item => item.id === this.checkout.form.payment.method)
        : null
    },

    selectedPaymentMethodCreditProducts () {
      return this.selectedPaymentMethod && this.cart.creditProducts.length
        ? this.cart.creditProducts.filter(product => product.type === this.selectedPaymentMethod.type)
        : []
    }
  },

  watch: {
    'cart.loading': {
      async handler (val) {
        if (!val && this.cart.id && this.checkout.form.delivery.city.value.id) {
          await this.setCityDeliveryOptions()
          this.togglePaymentTab(this.cart.type, true)

          if (!this.payment.company.loading) {
            await this.getCheckoutCartCreditProducts()
          }

          await this.searchFilial()
        }
      },
      immediate: true,
      deep: true
    },

    'cart.id': {
      async handler (val) {
        if (val && !this.cart.loading && this.checkout.form.delivery.city.value.id) {
          await this.setCityDeliveryOptions()
          this.togglePaymentTab(this.cart.type, true)

          if (!this.payment.company.loading) {
            await this.getCheckoutCartCreditProducts()
          }

          await this.searchFilial()
        }
      },
      immediate: true,
      deep: true
    },

    'location.city.id': {
      async handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue && newValue !== this.checkout.form.delivery.city.value.id) {
          this.changeCity(this.location.city)
          await this.searchCity()
        }

        await this.searchFilial()
      },
      immediate: true,
      deep: true
    },

    'checkout.form.delivery.city.value.id': {
      async handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue && !this.cart.loading && this.cart.id) {
          await this.setCityDeliveryOptions()
          this.togglePaymentTab(this.cart.type, true)
          await this.getCheckoutCartCreditProducts()
          await this.searchCity()
        }

        await this.searchFilial()
      },
      immediate: true,
      deep: true
    },

    'checkout.form.delivery.method': {
      handler (val) {
        if (val) {
          this.searchFilial()
        }
      },
      immediate: false,
      deep: true
    },

    '$auth.loggedIn': {
      handler (val) {
        if (val) {
          this.$store.dispatch('checkout/setUserNameValue', this.$auth.user.name)
          this.$store.dispatch('checkout/setUserLastnameValue', this.$auth.user.lastname)
          this.$store.dispatch('checkout/setUserPatronymicValue', this.$auth.user.patronymic)
          this.$store.dispatch('checkout/setUserPhone', this.$auth.user.phone)
          this.$store.dispatch('checkout/setUserPhoneModel', this.$auth.user.phone.replace(this.country.calling_code, ''))
          this.$store.dispatch('checkout/setUserEmailValue', this.$auth.user.email)

          this.validateUserName() // Autofocus issue
        }
      },
      immediate: true,
      deep: true
    }
  },

  created () {
    this.user.phone.country = this.country
    this.delivery.recipient.phone.country = this.country
    this.payment.installment.phone.country = this.country
    this.payment.installment.employer.phone.country = this.country
  },

  mounted () {
    this.fetchMajorCities()

    this.$gtm.push({
      event: 'begin_checkout',
      ecommerce: {
        items: this.cart.items.map(item => ({
          item_name: item.title,
          item_id: item.cartable_id,
          price: item.store.price.current.toFixed(2),
          item_brand: item.brand,
          item_category: item.breadcrumbs.length ? item.breadcrumbs[0].title : '',
          item_category2: item.breadcrumbs.length ? item.breadcrumbs[item.breadcrumbs.length - 1].title : '',
          quantity: item.quantity
        }))
      }
    })
  },

  methods: {
    toggleAuthModal () {
      this.$store.dispatch('modal/setAuthActive', !this.authModal.active)
    },

    formatter (date) {
      return date.toLocaleDateString()
    },

    validateUserNameOnKeydown () {
      if (this.checkout.form.user.name.value.length === 0) {
        this.$store.dispatch('checkout/validateUserName', '')
      }
    },

    validateUserNameOnKeyup () {
      if (this.checkout.form.user.name.error === '') {
        return
      }

      this.validateUserName()
    },

    validateUserName () {
      if (this.checkout.form.user.name.value.length >= 1) {
        this.$store.dispatch('checkout/validateUserName', '')
      } else if (this.checkout.form.user.name.value.length >= 1 && this.checkout.form.user.name.value.length < 3) {
        this.$store.dispatch('checkout/validateUserName', this.$t('pages.checkout.user.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateUserName', this.$t('pages.checkout.user.name.errors.required'))
      }
    },

    validateUserEmailOnKeyup () {
      if (this.checkout.form.user.email.value.length === 0) {
        this.$store.dispatch('checkout/validateUserEmail', '')
      }

      if (this.checkout.form.user.email.error === '') {
        return
      }

      this.validateUserEmail()
    },

    validateUserEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.checkout.form.user.email.value) || this.checkout.form.user.email.value === '') {
        this.$store.dispatch('checkout/validateUserEmail', '')
      } else {
        this.$store.dispatch('checkout/validateUserEmail', this.$t('pages.checkout.user.email.errors.invalid'))
      }
    },

    validateDeliveryRecipientName () {
      if (this.checkout.form.delivery.recipient.name.value.length >= 1) {
        this.$store.dispatch('checkout/validateDeliveryRecipientName', '')
      } else if (this.checkout.form.delivery.recipient.name.value.length >= 1 && this.checkout.form.delivery.recipient.name.value.length < 3) {
        this.$store.dispatch('checkout/validateDeliveryRecipientName', this.$t('pages.checkout.user.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateDeliveryRecipientName', this.$t('pages.checkout.user.name.errors.required'))
      }
    },

    validateDeliveryRecipientEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.checkout.form.delivery.recipient.email.value) || this.checkout.form.delivery.recipient.email.value === '') {
        this.$store.dispatch('checkout/validateDeliveryRecipientEmail', '')
      } else {
        this.$store.dispatch('checkout/validateDeliveryRecipientEmail', this.$t('pages.checkout.user.email.errors.invalid'))
      }
    },

    onUserPhoneInput (number, phone) {
      this.$store.dispatch('checkout/setUserPhone', phone.number)

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.$store.dispatch('checkout/setUserPhoneError', '')
      } else {
        this.$store.dispatch('checkout/setUserPhoneError', this.$t('pages.checkout.user.phone.errors.invalid'))
      }
    },

    onRecipientPhoneInput (number, phone) {
      this.$store.dispatch('checkout/setDeliveryRecipientPhone', phone.number)

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.$store.dispatch('checkout/setDeliveryRecipientPhoneError', '')
      } else {
        this.$store.dispatch('checkout/setDeliveryRecipientPhoneError', this.$t('pages.checkout.user.phone.errors.invalid'))
      }
    },

    validateInstallmentName () {
      if (this.checkout.form.payment.installment.name.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentName', '')
      } else if (this.checkout.form.payment.installment.name.value.length >= 1 && this.checkout.form.payment.installment.name.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentName', this.$t('pages.checkout.user.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentName', this.$t('pages.checkout.user.name.errors.required'))
      }
    },

    validateInstallmentLastname () {
      if (this.checkout.form.payment.installment.lastname.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentLastname', '')
      } else if (this.checkout.form.payment.installment.lastname.value.length >= 1 && this.checkout.form.payment.installment.lastname.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentLastname', this.$t('pages.checkout.user.lastname.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentLastname', this.$t('pages.checkout.user.lastname.errors.required'))
      }
    },

    validateInstallmentPatronymic () {
      if (this.checkout.form.payment.installment.lastname.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentPatronymic', '')
      } else if (this.checkout.form.payment.installment.lastname.value.length >= 1 && this.checkout.form.payment.installment.lastname.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentPatronymic', this.$t('pages.checkout.user.lastname.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentPatronymic', this.$t('pages.checkout.user.lastname.errors.required'))
      }
    },

    onInstallmentPhoneInput (number, phone) {
      this.$store.dispatch('checkout/setInstallmentPhone', phone.number)

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.$store.dispatch('checkout/setInstallmentPhoneError', '')
      } else {
        this.$store.dispatch('checkout/setInstallmentPhoneError', this.$t('pages.checkout.user.phone.errors.invalid'))
      }
    },

    validateInstallmentEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.checkout.form.payment.installment.email.value) || this.checkout.form.payment.installment.email.value === '') {
        this.$store.dispatch('checkout/validateInstallmentEmail', '')
      } else {
        this.$store.dispatch('checkout/validateInstallmentEmail', this.$t('pages.checkout.user.email.errors.invalid'))
      }
    },

    validateInstallmentTin () {
      if (this.checkout.form.payment.installment.tin.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentTin', '')
      } else if (this.checkout.form.payment.installment.tin.value.length >= 1 && this.checkout.form.payment.installment.tin.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentTin', this.$t('pages.checkout.installment.tin.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentTin', this.$t('pages.checkout.installment.tin.errors.required'))
      }
    },

    validateInstallmentPassportSeries () {
      if (this.checkout.form.payment.installment.passport.series.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentPassportSeries', '')
      } else if (this.checkout.form.payment.installment.passport.series.value.length >= 1 && this.checkout.form.payment.installment.passport.series.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentPassportSeries', this.$t('pages.checkout.installment.passport.series.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentPassportSeries', this.$t('pages.checkout.installment.passport.series.errors.required'))
      }
    },

    validateInstallmentPassportNumber () {
      if (this.checkout.form.payment.installment.passport.number.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentPassportNumber', '')
      } else if (this.checkout.form.payment.installment.passport.number.value.length >= 1 && this.checkout.form.payment.installment.passport.number.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentPassportNumber', this.$t('pages.checkout.installment.passport.number.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentPassportNumber', this.$t('pages.checkout.installment.passport.number.errors.required'))
      }
    },

    validateInstallmentPassportIssuer () {
      if (this.checkout.form.payment.installment.passport.issuer.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentPassportIssuer', '')
      } else if (this.checkout.form.payment.installment.passport.issuer.value.length >= 1 && this.checkout.form.payment.installment.passport.issuer.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentPassportIssuer', this.$t('pages.checkout.installment.passport.issuer.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentPassportIssuer', this.$t('pages.checkout.installment.passport.issuer.errors.required'))
      }
    },

    validateInstallmentPassportIssueDate () {
      if (this.checkout.form.payment.installment.passport.issue_date.value) {
        this.$store.dispatch('checkout/validateInstallmentPassportIssueDate', '')
      } else {
        this.$store.dispatch('checkout/validateInstallmentPassportIssueDate', this.$t('pages.checkout.installment.passport.issue_date.errors.required'))
      }
    },

    validateInstallmentPassportExpirationDate () {
      if (this.checkout.form.payment.installment.passport.expiration_date.value) {
        this.$store.dispatch('checkout/validateInstallmentPassportExpirationDate', '')
      } else {
        this.$store.dispatch('checkout/validateInstallmentPassportExpirationDate', this.$t('pages.checkout.installment.passport.expiration_date.errors.required'))
      }
    },

    validateInstallmentAddressRegistration () {
      if (this.checkout.form.payment.installment.address.registration.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentAddressRegistration', '')
      } else if (this.checkout.form.payment.installment.address.registration.value.length >= 1 && this.checkout.form.payment.installment.address.registration.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentAddressRegistration', this.$t('pages.checkout.installment.address.registration.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentAddressRegistration', this.$t('pages.checkout.installment.address.registration.errors.required'))
      }
    },

    validateInstallmentAddressResidence () {
      if (this.checkout.form.payment.installment.address.residence.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentAddressResidence', '')
      } else if (this.checkout.form.payment.installment.address.residence.value.length >= 1 && this.checkout.form.payment.installment.address.residence.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentAddressResidence', this.$t('pages.checkout.installment.address.residence.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentAddressResidence', this.$t('pages.checkout.installment.address.residence.errors.required'))
      }
    },

    validateInstallmentAddressZip () {
      if (this.checkout.form.payment.installment.address.zip.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentAddressZip', '')
      } else if (this.checkout.form.payment.installment.address.zip.value.length >= 1 && this.checkout.form.payment.installment.address.zip.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentAddressZip', this.$t('pages.checkout.installment.address.zip.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentAddressZip', this.$t('pages.checkout.installment.address.zip.errors.required'))
      }
    },

    validateInstallmentEducationDegree () {
      if (this.checkout.form.payment.installment.education.degree.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEducationDegree', '')
      } else if (this.checkout.form.payment.installment.education.degree.value.length >= 1 && this.checkout.form.payment.installment.education.degree.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEducationDegree', this.$t('pages.checkout.installment.education.degree.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEducationDegree', this.$t('pages.checkout.installment.education.degree.errors.required'))
      }
    },

    validateInstallmentEducationInstitution () {
      if (this.checkout.form.payment.installment.education.institution.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEducationInstitution', '')
      } else if (this.checkout.form.payment.installment.education.institution.value.length >= 1 && this.checkout.form.payment.installment.education.institution.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEducationInstitution', this.$t('pages.checkout.installment.education.institution.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEducationInstitution', this.$t('pages.checkout.installment.education.institution.errors.required'))
      }
    },

    validateInstallmentEmployerTitle () {
      if (this.checkout.form.payment.installment.employer.title.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEmployerTitle', '')
      } else if (this.checkout.form.payment.installment.employer.title.value.length >= 1 && this.checkout.form.payment.installment.employer.title.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEmployerTitle', this.$t('pages.checkout.installment.employer.title.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEmployerTitle', this.$t('pages.checkout.installment.employer.title.errors.required'))
      }
    },

    validateInstallmentEmployerPhone () {
      if (this.checkout.form.payment.installment.employer.phone.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEmployerPhone', '')
      } else if (this.checkout.form.payment.installment.employer.phone.value.length >= 1 && this.checkout.form.payment.installment.employer.phone.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEmployerPhone', this.$t('pages.checkout.installment.employer.phone.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEmployerPhone', this.$t('pages.checkout.installment.employer.phone.errors.required'))
      }
    },

    onInstallmentEmployerPhoneInput (number, phone) {
      this.$store.dispatch('checkout/setInstallmentEmployerPhone', phone.number)

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.$store.dispatch('checkout/setInstallmentEmployerPhoneError', '')
      } else {
        this.$store.dispatch('checkout/setInstallmentEmployerPhoneError', this.$t('pages.checkout.installment.employer.phone.errors.invalid'))
      }
    },

    validateInstallmentEmployerAddress () {
      if (this.checkout.form.payment.installment.employer.address.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEmployerAddress', '')
      } else if (this.checkout.form.payment.installment.employer.address.value.length >= 1 && this.checkout.form.payment.installment.employer.address.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEmployerAddress', this.$t('pages.checkout.installment.employer.address.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEmployerAddress', this.$t('pages.checkout.installment.employer.address.errors.required'))
      }
    },

    validateInstallmentEmployerJobTitle () {
      if (this.checkout.form.payment.installment.employer.job_title.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEmployerJobTitle', '')
      } else if (this.checkout.form.payment.installment.employer.job_title.value.length >= 1 && this.checkout.form.payment.installment.employer.job_title.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEmployerJobTitle', this.$t('pages.checkout.installment.employer.job_title.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEmployerJobTitle', this.$t('pages.checkout.installment.employer.job_title.errors.required'))
      }
    },

    validateInstallmentEmployerOccupation () {
      if (this.checkout.form.payment.installment.employer.occupation.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentEmployerOccupation', '')
      } else if (this.checkout.form.payment.installment.employer.occupation.value.length >= 1 && this.checkout.form.payment.installment.employer.occupation.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentEmployerOccupation', this.$t('pages.checkout.installment.employer.occupation.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentEmployerOccupation', this.$t('pages.checkout.installment.employer.occupation.errors.required'))
      }
    },

    validateInstallmentFamilyStatus () {
      if (this.checkout.form.payment.installment.family.status.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentFamilyStatus', '')
      } else if (this.checkout.form.payment.installment.family.status.value.length >= 1 && this.checkout.form.payment.installment.family.status.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentFamilyStatus', this.$t('pages.checkout.installment.family.status.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentFamilyStatus', this.$t('pages.checkout.installment.family.status.errors.required'))
      }
    },

    validateInstallmentFamilyNumberOfChildren () {
      if (this.checkout.form.payment.installment.family.number_of_children.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentFamilyNumberOfChildren', '')
      } else if (this.checkout.form.payment.installment.family.number_of_children.value.length >= 1 && this.checkout.form.payment.installment.family.number_of_children.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentFamilyNumberOfChildren', this.$t('pages.checkout.installment.family.number_of_children.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentFamilyNumberOfChildren', this.$t('pages.checkout.installment.family.number_of_children.errors.required'))
      }
    },

    validateInstallmentFamilyAgeOfChildren () {
      if (this.checkout.form.payment.installment.family.age_of_children.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentFamilyAgeOfChildren', '')
      } else if (this.checkout.form.payment.installment.family.age_of_children.value.length >= 1 && this.checkout.form.payment.installment.family.age_of_children.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentFamilyAgeOfChildren', this.$t('pages.checkout.installment.family.age_of_children.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentFamilyAgeOfChildren', this.$t('pages.checkout.installment.family.age_of_children.errors.required'))
      }
    },

    validateInstallmentFamilyNumberOfInhabitants () {
      if (this.checkout.form.payment.installment.family.number_of_inhabitants.value.length >= 1) {
        this.$store.dispatch('checkout/validateInstallmentFamilyNumberOfInhabitants', '')
      } else if (this.checkout.form.payment.installment.family.number_of_inhabitants.value.length >= 1 && this.checkout.form.payment.installment.family.number_of_inhabitants.value.length < 3) {
        this.$store.dispatch('checkout/validateInstallmentFamilyNumberOfInhabitants', this.$t('pages.checkout.installment.family.number_of_inhabitants.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateInstallmentFamilyNumberOfInhabitants', this.$t('pages.checkout.installment.family.number_of_inhabitants.errors.required'))
      }
    },

    hideCityDropDown () {
      this.delivery.city.active = false
    },

    toggleCitySelect () {
      this.delivery.city.active = !this.delivery.city.active

      this.$nextTick(() => {
        this.$refs.city.focus()
      })
    },

    changeCity (city) {
      this.$store.dispatch('checkout/setDeliveryCity', city)
      this.$store.dispatch('checkout/setDeliveryCityQuery', this.checkout.form.delivery.city.value.name)
      this.hideCityDropDown()
      this.$store.dispatch('checkout/setDeliveryMethodItems', [])
      this.$store.dispatch('checkout/setDeliveryMethod', null)
      this.$store.dispatch('checkout/setPaymentMethod', null)
    },

    async fetchMajorCities () {
      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/cities/major`, { progress: false })
        .then((res) => {
          this.$store.dispatch('checkout/setDeliveryMajorCities', res.data)
        })
    },

    searchCityTyping () {
      clearTimeout(this.delivery.city.timeout)

      this.delivery.city.timeout = setTimeout(() => {
        this.searchCity()
      }, this.delivery.city.timer)
    },

    async searchCity () {
      if (this.checkout.form.delivery.city.query && this.checkout.form.delivery.city.query.length < 3) {
        return
      } else if (this.checkout.form.delivery.city.query === '') {
        this.$store.dispatch('checkout/setDeliveryCitiesItems', this.checkout.form.delivery.cities.major)

        return
      }

      this.delivery.city.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/cities?q=${this.checkout.form.delivery.city.query}`, { progress: false })
        .then((res) => {
          this.$store.dispatch('checkout/setDeliveryCitiesItems', res.data)
        })
        .finally(() => (this.delivery.city.loading = false))
    },

    async setCityDeliveryOptions () {
      if (this.delivery.methods.loading) {
        return
      }

      this.delivery.methods.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/checkout/carts/${this.cart.id}/cities/${this.checkout.form.delivery.city.value.id}/delivery-methods`, { progress: false })
        .then((res) => {
          this.$store.dispatch('checkout/setDeliveryMethodItems', res.data)
          this.changeDeliveryMethod(this.selectedDeliveryMethod || this.checkout.form.delivery.methods.items[0])

          if (!this.checkout.form.payment.tab) {
            this.$store.dispatch('checkout/setPaymentTab', this.cart.type)
          }

          if (this.selectedPaymentMethod) {
            // this.changePaymentMethod(this.selectedPaymentMethod.id)
          } else if (typeof this.cart.creditProduct === 'object' &&
            this.cart.creditProduct !== null &&
            this.cart.creditProduct.type &&
            this.cart.type === 'installment' &&
            this.selectedDeliveryMethod.paymentMethods.filter(method => method.type === this.cart.creditProduct.type).length > 0
          ) {
            this.changePaymentMethod(this.selectedDeliveryMethod.paymentMethods.filter(method => method.type === this.cart.creditProduct.type)[0].id)
          } else if (this.selectedDeliveryMethod.paymentMethods.filter(method => method.tab === this.checkout.form.payment.tab).length > 0) {
            this.changePaymentMethod(this.selectedDeliveryMethod.paymentMethods.filter(method => method.tab === this.checkout.form.payment.tab)[0].id)
          } else {
            this.changePaymentMethod(this.selectedDeliveryMethod.paymentMethods[0].id)
          }

          // this.changePaymentMethod(this.selectedPaymentMethod
          //   ? this.selectedPaymentMethod.id
          //   : (this.selectedDeliveryMethod.paymentMethods.filter(method => method.tab === this.checkout.form.payment.tab).length > 0
          //     ? this.selectedDeliveryMethod.paymentMethods.filter(method => method.tab === this.checkout.form.payment.tab)[0].id
          //     : this.selectedDeliveryMethod.paymentMethods[0].id
          //   )
          // )
        })
        .finally(() => (this.delivery.methods.loading = false))
    },

    async updateQuantity (item) {
      await this.setCityDeliveryOptions()
      await this.getCheckoutCartCreditProducts()
      await this.searchCity()
      await this.searchFilial()
    },

    clearCity () {
      this.$store.dispatch('checkout/setDeliveryCitiesItems', this.checkout.form.delivery.cities.major)
      this.$store.dispatch('checkout/setDeliveryCityQuery', '')
      this.$store.dispatch('checkout/setDeliveryCity', { id: '', name: '' })
      this.$store.dispatch('checkout/setDeliveryMethodItems', [])
      this.$store.dispatch('checkout/setDeliveryMethod', null)
      this.$store.dispatch('checkout/setPaymentMethod', null)
    },

    changeDeliveryMethod (deliveryMethod) {
      if (!deliveryMethod) {
        return
      }

      this.$store.dispatch('checkout/setDeliveryMethod', deliveryMethod.id)
      this.setDefaultFilial()
    },

    changePaymentMethod (paymentMethod) {
      if (this.checkout.form.payment.tab === 'installment' &&
        'id' in this.cart.term &&
        'creditProducts' in this.cart &&
        this.cart.creditProducts.length > 0
      ) {
        let foundPaymentMethod = this.formDeliveryMethodPaymentMethods.find(method => method.id === paymentMethod)

        if (typeof foundPaymentMethod === 'undefined') {
          foundPaymentMethod = this.formDeliveryMethodPaymentMethods[0]
        }

        paymentMethod = foundPaymentMethod.id
      }

      this.$store.dispatch('checkout/setPaymentMethod', paymentMethod)

      let creditProduct = this.selectedPaymentMethodCreditProducts.find(creditProduct => creditProduct.terms.find(term => term.id === this.cart.term.id))

      if (typeof creditProduct === 'undefined') {
        creditProduct = this.selectedPaymentMethodCreditProducts[0]
      }

      if (creditProduct) {
        this.changeCreditProduct(creditProduct, true)
      }
    },

    storeShipmentTimeOfDeliveryMethod (deliveryMethod) {
      // Add 1 day if the current time is more than the shipment_before time
      const shipTodayAddDays = this.$moment().locale(this.$i18n.locale).format('HH:mm:ss') < deliveryMethod.shipment_before ? 0 : 1

      const from = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.minStoreShipmentTime.shipment_min, `${this.minStoreShipmentTime.shipment_min_unit.name}s`)
        .add(this.minStoreShipmentTime.stockStatus.shipment_time_min, `${this.minStoreShipmentTime.stockStatus.shipment_time_min_unit.name}s`)
        .add(deliveryMethod.delivery_time_min, `${deliveryMethod.delivery_time_min_unit.name}s`)

      const to = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.maxStoreShipmentTime.shipment_max, `${this.maxStoreShipmentTime.shipment_max_unit.name}s`)
        .add(this.maxStoreShipmentTime.stockStatus.shipment_time_max, `${this.maxStoreShipmentTime.stockStatus.shipment_time_max_unit.name}s`)
        .add(deliveryMethod.delivery_time_max, `${deliveryMethod.delivery_time_max_unit.name}s`)

      if (to.year() === from.year() && to.month() === from.month() && to.date() === from.date()) {
        return to.format('DD MMMM')
      } else if (to.year() === from.year() && to.month() === from.month() && to.date() !== from.date()) {
        return `${from.format('DD')} — ${to.format('DD MMMM')}`
      } else if (to.year() === from.year() && to.month() !== from.month() && to.date() !== from.date()) {
        return `${from.format('DD MMM')} — ${to.format('DD MMM')}`
      } else {
        return `${from.format('ll')} — ${to.format('ll')}`
      }
    },

    hideFilialDropDown () {
      this.delivery.filial.active = false
    },

    toggleFilialSelect () {
      this.delivery.filial.active = !this.delivery.filial.active

      this.$nextTick(() => {
        // this.$refs.filial.focus()
      })
    },

    changeFilial (filial) {
      this.$store.dispatch('checkout/setDeliveryFilial', filial)
      this.$store.dispatch('checkout/setDeliveryFilialMap', false)
      this.hideFilialDropDown()
      this.goToPayment()
    },

    async searchFilial () {
      if (!this.cart.id ||
        !this.checkout.form.delivery.city.value.id ||
        !this.checkout.form.delivery.method ||
        this.delivery.filial.loading
      ) {
        return
      }

      this.delivery.filial.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/checkout/carts/${this.cart.id}/cities/${this.checkout.form.delivery.city.value.id}/delivery-methods/${this.checkout.form.delivery.method}/filials`, { progress: false })
        .then((res) => {
          this.$store.dispatch('checkout/setDeliveryFilials', {
            deliveryMethodId: this.checkout.form.delivery.method,
            filials: res.data
          })
          this.setDefaultFilial()
        })
        .finally(() => (this.delivery.filial.loading = false))
    },

    setDefaultFilial () {
      if (this.selectedDeliveryMethod &&
        this.selectedDeliveryMethod.type === 'filial' &&
        this.selectedDeliveryMethod.filials.length > 0 &&
        this.closestFilial
      ) {
        this.$store.dispatch('checkout/setDeliveryFilial', this.closestFilial)
      } else if (this.selectedDeliveryMethod &&
        this.selectedDeliveryMethod.type === 'filial' &&
        this.selectedDeliveryMethod.filials.length > 0
      ) {
        this.$store.dispatch('checkout/setDeliveryFilial', this.selectedDeliveryMethod.filials[0])
      } else {
        this.$store.dispatch('checkout/setDeliveryFilial', { id: '', name: '' })
      }

      this.$store.dispatch('checkout/setDeliveryFilialMap', false)
    },

    distanceToClosestFilial (to) {
      return Math.sqrt((this.location.lat - to.lat) ** 2 + (this.location.lng - to.lng) ** 2)
    },

    clearFilial () {
      this.$store.dispatch('checkout/setDeliveryFilial', {
        id: '',
        prefix: '',
        name: '',
        address: ''
      })

      this.$store.dispatch('checkout/setDeliveryFilialMap', false)
    },

    togglePaymentTab (tab, init = false) {
      if (this.cart.creditProducts.length === 0) {
        tab = 'cart'
      }

      this.$store.dispatch('checkout/setPaymentTab', tab)

      if (!init) {
        const filtered = this.formDeliveryMethodPaymentMethods.filter(method => method.tab === tab)

        if (filtered.length) {
          this.changePaymentMethod(filtered[0].id)
        }
      }

      if (tab === 'installment' &&
        typeof this.cart.creditProduct === 'object' &&
        Object.keys(this.cart.creditProduct).length === 0 &&
        'creditProducts' in this.cart &&
        this.selectedPaymentMethodCreditProducts.length !== 0
      ) {
        this.changeCreditProduct(this.selectedPaymentMethodCreditProducts[0], true)
      } else if (tab === 'cart') {
        this.$store.dispatch('cart/setCartCreditProduct', {})
        this.$store.dispatch('cart/setCartTerm', {
          payments: 1
        })
      }

      this.setCartType(tab)
    },

    hideCreditProductsDropDown () {
      this.payment.company.active = false
    },

    async changeCreditProduct (creditProduct, lazy = false) {
      await this.$store.dispatch('cart/setCartCreditProduct', creditProduct)

      let term

      if ('term' in this.cart &&
        'payments' in this.cart.term &&
        creditProduct.terms
      ) {
        term = creditProduct.terms.find(term => term.payments === this.cart.term.payments)

        if (typeof term === 'undefined') {
          const x = this.cart.term.payments
          const creditTerms = JSON.parse(JSON.stringify(creditProduct.terms))

          term = creditTerms.sort((a, b) => Math.abs(x - a.payments) - Math.abs(x - b.payments))[0]
        }
      }

      this.changeTerm(term || creditProduct.terms[creditProduct.terms.length - 1], true)

      // nuxt-img
      // if (lazy && this.$refs.company && this.$refs.company.length !== 0) {
      //   this.$refs.company[0].classList.add('lazyload')
      // }

      if (this.payment.installment.requireNameFields.includes(this.cart.creditProduct.service)) {
        if (this.userNameValue.length) {
          this.$store.dispatch('checkout/setInstallmentName', this.userNameValue)
        }

        if (this.userLastnameValue.length) {
          this.$store.dispatch('checkout/setInstallmentLastname', this.userLastnameValue)
        }

        if (this.userPatronymicValue.length) {
          this.$store.dispatch('checkout/setInstallmentPatronymic', this.userPatronymicValue)
        }
      }

      if (this.payment.installment.requirePhone.includes(this.cart.creditProduct.service)) {
        if (this.userPhoneModel.length) {
          this.$nextTick(() => this.$store.dispatch('checkout/setInstallmentPhoneModel', this.userPhoneModel))
        }
      }

      if (this.payment.installment.requireEmail.includes(this.cart.creditProduct.service)) {
        if (this.userEmailValue.length) {
          this.$store.dispatch('checkout/setInstallmentEmail', this.userEmailValue)
        }
      }

      this.hideCreditProductsDropDown()

      this.$store.dispatch('cart/syncCart', false)
    },

    hideTermsDropDown () {
      this.payment.term.active = false
    },

    setCartType (tab) {
      this.$store.dispatch('checkout/setPaymentType', tab)
      this.$store.dispatch('cart/setCartType', tab)
      this.$store.dispatch('cart/syncCart', false)
    },

    getPaymentMethodCommissionDescription (method) {
      if (method.commission.type === 'percent' && method.commission.value > 0) {
        return `${method.commission.value} %`
      } else if (method.commission.type === 'amount' && method.commission.value > 0) {
        return `${method.commission.value} ${this.currency.sign}`
      } else {
        return ''
      }
    },

    toggleFormNoteVisibility () {
      this.note.show = !this.note.show

      if (this.note.show) {
        this.$nextTick(() => {
          this.$refs.note.$el.focus()
        })
      }
    },

    goToPayment () {
      this.$store.dispatch('checkout/toggleTab', 'payment')

      this.changePaymentMethod(this.selectedPaymentMethod
        ? this.selectedPaymentMethod.id
        : this.selectedDeliveryMethod.paymentMethods[0].id)
    },

    changeTerm (term, lazy = false) {
      this.$store.dispatch('cart/setCartTerm', term)

      // nuxt-img
      // if (lazy && this.$refs.term) {
      //   this.$refs.term.classList.add('lazyload')
      // }

      this.hideTermsDropDown()
    },

    changeInitialInstallment () {
      const installmentInitial = this.positiveInt(this.checkout.form.payment.installment.initial)

      this.$store.dispatch('checkout/setInstallmentInitial', installmentInitial > this.total
        ? this.total
        : installmentInitial)

      this.$store.dispatch('cart/setInitialInstallment', this.checkout.form.payment.installment.initial)
    },

    async getCheckoutCartCreditProducts () {
      await this.$store.dispatch('cart/setCartCreditProducts')

      this.payment.company.loading = false
    },

    positiveInt (val) {
      val = parseInt(val)

      if (val < 0) {
        val = val * -1
      }

      return val || 0
    },

    openCreditProductTypeInfoModal (creditProduct) {
      this.$store.dispatch('modal/setInfoModalTitle', creditProduct.title)
      this.$store.dispatch('modal/setInfoModalDescription', creditProduct.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    submit () {
      this.buttons.checkout.loading = true

      const data = {
        cart: {
          id: this.cart.id
        },
        user: {
          name: this.checkout.form.user.name.value,
          lastname: this.checkout.form.user.lastname.value,
          patronymic: this.checkout.form.user.patronymic.value,
          phone: this.checkout.form.user.phone.number,
          email: this.checkout.form.user.email.value
        },
        delivery: {
          method_id: this.checkout.form.delivery.method,
          city_id: this.checkout.form.delivery.city.value.id,
          price: this.selectedDeliveryMethod.price
        },
        payment: {
          method_id: this.checkout.form.payment.method,
          price: this.total
        },
        commission: this.totalCommission,
        callback: this.checkout.form.callback,
        note: this.checkout.form.note.body
      }

      const referrer = this.getReferrer()

      if (referrer) {
        data.source = referrer

        this.flushReferrer()
      }

      if (this.selectedDeliveryMethod && this.selectedDeliveryMethod.type === 'filial') {
        data.delivery.filial_id = this.checkout.form.delivery.filial.value.id
      } else if (this.selectedDeliveryMethod && this.selectedDeliveryMethod.type === 'address') {
        data.delivery.address = {
          street: this.checkout.form.delivery.address.street.value,
          zip: this.checkout.form.delivery.address.zip.value,
          house: this.checkout.form.delivery.address.house.value,
          apartment: this.checkout.form.delivery.address.apartment.value,
          floor: this.checkout.form.delivery.address.floor.value,
          intercom: this.checkout.form.delivery.address.intercom.value
        }
      }

      if (this.cart.type === 'installment') {
        data.installment = {
          term: this.cart.term,
          initial: this.cart.installment.initial,
          monthly: this.installmentPerMonthPrice,
          name: this.checkout.form.payment.installment.name.value,
          lastname: this.checkout.form.payment.installment.lastname.value,
          patronymic: this.checkout.form.payment.installment.patronymic.value,
          phone: this.checkout.form.payment.installment.phone.number,
          email: this.checkout.form.payment.installment.email.value,
          birthday: this.checkout.form.payment.installment.birthday.value,
          tin: this.checkout.form.payment.installment.tin.value,
          passport: {
            series: this.checkout.form.payment.installment.passport.series.value,
            number: this.checkout.form.payment.installment.passport.number.value,
            issuer: this.checkout.form.payment.installment.passport.issuer.value,
            issue_date: this.checkout.form.payment.installment.passport.issue_date.value,
            expiration_date: this.checkout.form.payment.installment.passport.expiration_date.value
          },
          address: {
            registration: this.checkout.form.payment.installment.address.registration.value,
            residence: this.checkout.form.payment.installment.address.residence.value,
            zip: this.checkout.form.payment.installment.address.zip.value
          },
          education: {
            degree: this.checkout.form.payment.installment.education.degree.value,
            institution: this.checkout.form.payment.installment.education.institution.value
          },
          employer: {
            occupation: this.checkout.form.payment.installment.employer.occupation.value,
            job_title: this.checkout.form.payment.installment.employer.job_title.value,
            phone: this.checkout.form.payment.installment.employer.phone.number,
            address: this.checkout.form.payment.installment.employer.address.value,
            title: this.checkout.form.payment.installment.employer.title.value
          },
          family: {
            status: this.checkout.form.payment.installment.family.status.value,
            number_of_children: this.checkout.form.payment.installment.family.number_of_children.value,
            age_of_children: this.checkout.form.payment.installment.family.age_of_children.value,
            number_of_inhabitants: this.checkout.form.payment.installment.family.number_of_inhabitants.value

          },
          third_person: {
            full_name: this.checkout.form.payment.installment.third_person.full_name.value,
            phone: this.checkout.form.payment.installment.third_person.phone.value,
            relations: this.checkout.form.payment.installment.third_person.relations.value
          },
          note: this.checkout.form.payment.installment.note.body
        }
      }

      if (this.delivery.recipient.checkbox) {
        data.delivery.recipient = data.user
      } else {
        data.delivery.recipient = {
          name: this.checkout.form.delivery.recipient.name.value,
          lastname: this.checkout.form.delivery.recipient.lastname.value,
          patronymic: this.checkout.form.delivery.recipient.patronymic.value,
          phone: this.checkout.form.delivery.recipient.phone.number,
          email: this.checkout.form.delivery.recipient.email.value
        }
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/orders`, data)
        .then((res) => {
          this.$gtm.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: res.data.id,
              affiliation: 'cart', // or 'fast order' for 1 click orders
              value: res.data.payments.reduce((total, p) => total + p.price + p.commission, 0).toFixed(2),
              currency: res.data.currency.code,
              items: res.data.deliveries.reduce((items, delivery) => items.concat(delivery.items), []).map(item => ({
                item_name: item.title,
                item_id: item.orderable_id,
                price: item.price.toFixed(2),
                item_brand: item.brand,
                item_category: item.breadcrumbs.length ? item.breadcrumbs[0].title : '',
                item_category2: item.breadcrumbs.length ? item.breadcrumbs[item.breadcrumbs.length - 1].title : '',
                quantity: item.quantity
              }))
            }
          })

          if (['liqpay', 'concordpay'].includes(res.payment.type)) {
            this.$store.dispatch('checkout/setPaymentGatewayType', res.payment.type)
            this.$store.dispatch('checkout/setPaymentGatewayForm', res.payment.data)

            this.$nextTick(() => {
              this.$refs[res.payment.type].$el.submit()
            })
          } else if (['paypal', 'novapay', 'privatbank.installments'].includes(res.payment.type)) {
            window.location.replace(res.payment.data)
          } else if (['ideabank.installments', 'paylater.installments'].includes(res.payment.type)) {
            this.$store.dispatch('order/setOrder', res.data)
            window.open(res.payment.data, '_blank')
            this.$router.push(this.localePath({ name: 'thanks' }))
          } else {
            this.$store.dispatch('order/setOrder', res.data)
            this.$router.push(this.localePath({ name: 'thanks' }))
          }
        })
        .catch((e) => {
          this.$store.dispatch('checkout/setNotificationMessage', this.$t('pages.checkout.errors.failed'))
          this.$store.dispatch('checkout/setNotificationClass', 'is-danger')
          this.notification.active = true
          this.buttons.checkout.loading = false
        })
        .finally(() => {
          // this.buttons.checkout.loading = false
        })
    }
  },

  head () {
    return {
      title: this.$t('pages.checkout.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.checkout.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.checkout.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.checkout.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
