
import { mapGetters } from 'vuex'

import component from '@/mixins/component.js'
import location from '@/mixins/location.js'

export default {
  mixins: [
    component,
    location
  ],

  async asyncData ({ params, $axios, app, route, error, redirect }) {
    try {
      const model = await app.$cacheFetch(
        {
          key: `get:/client/${app.$config.storeId}/${app.i18n.locale}/${app.store.state.country.iso_code}/pages/${params.slug}` + (route.query.page ? `?page=${route.query.page}` : ''),
          expire: app.$config.responseCacheLifetime
        },
        async () => {
          return await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/pages/${params.slug}` + (route.query.page ? `?page=${route.query.page}` : ''))
        }
      )

      if (model.data.page.redirect && model.data.page.redirect.active) {
        let params

        if (model.data.page.redirect.page.slug.includes('/')) {
          const slugs = model.data.page.redirect.page.slug.split('/')

          params = {
            slug: slugs[0],
            params: slugs[1]
          }
        } else {
          params = {
            slug: model.data.page.redirect.page.slug
          }
        }

        redirect(model.data.page.redirect.type, app.localePath({ name: 'slug', params }))
      }

      await app.store.dispatch('i18n/setRouteParams', model.data.alternate)

      return {
        model: model.data,
        prop: model.prop,
        component: model.component
      }
    } catch (e) {
      error({ statusCode: 404 })
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      location: 'location',
      logo: 'logo',
      recent: 'recent/recent'
    })
  },

  watch: {
    'recent.id': {
      async handler (val) {
        if (val === null) {
          return
        }

        if (this.component === 'product') {
          await this.$store.dispatch('recent/addToRecent', {
            itemable_type: 'App\\Models\\Product\\Product',
            itemable_id: this.model.id
          })
        }
      },
      immediate: true,
      deep: true
    }
  },

  mounted () {
    if (this.component === 'product' && this.location.city && this.location.city.id === null) {
      this.getLocation()
    }
  },

  head () {
    const script = []

    if (this.model.schemaOrg && this.model.schemaOrg.product) {
      script.push({
        type: 'application/ld+json',
        json: this.model.schemaOrg.product,
        body: true
      })
    }

    if (this.model.schemaOrg && this.model.schemaOrg.article) {
      script.push({
        type: 'application/ld+json',
        json: this.model.schemaOrg.article,
        body: true
      })
    }

    return {
      title: this.model.page.title,
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: `${this.$config.appUrl}/${this.model.page.canonical}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.model.page.description
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.model.page.schema_org_title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.model.page.schema_org_description
        },
        // {
        //   hid: 'og:image',
        //   property: 'og:image',
        //   content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        // },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.model.page.robots
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.model.page.keywords
        }
      ],
      script
    }
  }
}
