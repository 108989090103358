
import { mapGetters } from 'vuex'

export default {
  props: {
    hiddenMobile: {
      required: true,
      default () {
        return true
      },
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      authModal: 'modal/auth'
    })
  },

  methods: {
    toggleModal () {
      this.$store.dispatch('modal/setAuthActive', !this.authModal.active)
    }
  }
}
