
import { mapGetters } from 'vuex'

export default {
  props: {
    model: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    modal: {
      type: 'login'
    }
  }),

  computed: {
    ...mapGetters({
      authModal: 'modal/auth',
      country: 'country'
    }),

    inWishlist () {
      return this.$auth.user.wishlist.items.find(item => item.wishlistable_id === this.model.id)
    }
  },

  methods: {
    toggleModal () {
      if (this.authModal.active && this.$auth.loggedIn) {
        this.addToWishlist()
      }

      this.$store.dispatch('modal/setAuthActive', !this.authModal.active)
    },

    addToWishlist (modelType = this.model.class, modelId = this.model.id) {
      if (!this.$auth.loggedIn || !this.$auth.user) {
        this.$store.dispatch('modal/setAuthActive', true)

        return
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/wishlists`, {
        user_id: this.$auth.user.id,
        model: modelType,
        id: modelId
      })
        .then((res) => {
          this.$auth.fetchUser().then(() => {
            this.$emit('wished', {
              wishlists: res.data
            })
          })
        })
    }
  }
}
