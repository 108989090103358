
import Cart from '@/components/Cart'

export default {
  components: {
    Cart
  },

  layout: 'cart'
}
