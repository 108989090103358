
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

import cart from '@/mixins/cart.js'
import location from '@/mixins/location.js'

import CheckoutHeaderNav from '@/components/checkout/HeaderNav'

export default {
  components: {
    CheckoutHeaderNav,
    LazyCheckoutFooterNav: defineAsyncComponent(() => import('@/components/checkout/FooterNav')),
    LazyModalsCallbackModal: defineAsyncComponent(() => import('@/components/modals/CallbackModal')),
    LazyModalsEmailModal: defineAsyncComponent(() => import('@/components/modals/EmailModal')),
    LazyModalsInfoModal: defineAsyncComponent(() => import('@/components/modals/InfoModal')),
    LazyModalsAuthModal: defineAsyncComponent(() => import('@/components/modals/AuthModal'))
  },

  mixins: [
    cart,
    location
  ],

  computed: {
    ...mapGetters({
      authModal: 'modal/auth',
      info: 'modal/info',
      callback: 'modal/callback',
      location: 'location',
      email: 'modal/email'
    })
  },

  mounted () {
    if (this.location.city && this.location.city.id === null) {
      this.getLocation()
    }
    const html = document.documentElement
    html.style.overflow = null
    this.$store.dispatch('cart/setCartModalActive', false)
  },

  methods: {
    toggleAuthModal () {
      this.$store.dispatch('modal/setAuthActive', false)
    },

    toggleCallbackModal () {
      this.$store.dispatch('modal/setCallbackActive', false)
    },

    toggleEmailModal () {
      this.$store.dispatch('modal/setEmailActive', false)
      this.$store.dispatch('modal/setEmailTab', null)
    },

    toggleInfoModal () {
      this.$store.dispatch('modal/setInfoModalActive', !this.info.active)
      this.$store.dispatch('modal/setInfoModalTitle', '')
      this.$store.dispatch('modal/setInfoModalDescription', '')
      this.$store.dispatch('modal/setInfoModalPage', { slug: '' })
    }
  }
}
