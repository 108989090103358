
import { mapGetters } from 'vuex'

export default {
  props: {
    model: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    modal: {
      type: 'login'
    }
  }),

  computed: {
    ...mapGetters({
      authModal: 'modal/auth',
      country: 'country'
    })
  },

  methods: {
    toggleModal () {
      this.$store.dispatch('modal/setAuthActive', !this.authModal.active)
    },

    vote (modelType, modelId, helpful) {
      if (!this.$auth.loggedIn || !this.$auth.user) {
        this.$store.dispatch('modal/setAuthActive', true)

        return
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/votes`, {
        user_id: this.$auth.user.id,
        model: modelType,
        id: modelId,
        helpful
      },
      { progress: false })
        .then((res) => {
          this.$emit('voted', {
            votes: res.data,
            model: {
              id: modelId
            }
          })
        })
    }
  }
}
