import { render, staticRenderFns } from "./NavBottom.vue?vue&type=template&id=e001b258&"
import script from "./NavBottom.vue?vue&type=script&lang=js&"
export * from "./NavBottom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenusCatalogMenu: require('/app/components/menus/CatalogMenu.vue').default,MenusMobileMenu: require('/app/components/menus/MobileMenu.vue').default,Logo: require('/app/components/Logo.vue').default,Search: require('/app/components/Search.vue').default,ButtonsAuth: require('/app/components/buttons/Auth.vue').default,ButtonsCompare: require('/app/components/buttons/Compare.vue').default,ButtonsCart: require('/app/components/buttons/Cart.vue').default,ButtonsSearch: require('/app/components/buttons/Search.vue').default})
